html {
    max-width: 100%;
    /*overflow-x: hidden;*/

    min-height: 100%;
    min-height: calc(100% + constant(safe-area-inset-top));
    min-height: calc(100% + env(safe-area-inset-top));
}

body {
    max-width: 100%;
    /*overflow-x: hidden;*/

    min-height: 100%;
    min-height: calc(100% + constant(safe-area-inset-top));
    min-height: calc(100% + env(safe-area-inset-top));

    touch-action: manipulation;
}

.Toastify__toast {
    border-radius: 10px !important;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px !important;
}

.App {
    text-align: center;
}

.App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 40vmin;
    pointer-events: none;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

.App-header-ios {
    /* Status bar height on iOS 10 */
    min-height: 76px !important;
    /* Status bar height on iOS 11.0 */
    min-height: calc(56px + constant(safe-area-inset-top)) !important;
    /* Status bar height on iOS 11+ */
    min-height: calc(56px + env(safe-area-inset-top)) !important;
    /* Status bar height on iOS 10 */
    padding-top: 20px;
    /* Status bar height on iOS 11.0 */
    padding-top: constant(safe-area-inset-top, 20px);
    /* Status bar height on iOS 11+ */
    padding-top: env(safe-area-inset-top, 20px);
}

.App-header-ios-fallback {
    padding-top: 20px;
}

.App-subheader-ios {
    /* Status bar height on iOS 10 */
    min-height: 68px !important;
    /* Status bar height on iOS 11.0 */
    min-height: calc(48px + constant(safe-area-inset-top)) !important;
    /* Status bar height on iOS 11+ */
    min-height: calc(48px + env(safe-area-inset-top)) !important;
    /* Status bar height on iOS 10 */
    padding-top: 20px;
    /* Status bar height on iOS 11.0 */
    padding-top: constant(safe-area-inset-top, 20px);
    /* Status bar height on iOS 11+ */
    padding-top: env(safe-area-inset-top, 20px);
}

.App-subheader-ios-fallback {
    padding-top: 20px;
}

.App-content-ios {
    /* Status bar height on iOS 10 */
    padding-top: 76px !important;
    /* Status bar height on iOS 11.0 */
    padding-top: calc(56px + constant(safe-area-inset-top)) !important;
    /* Status bar height on iOS 11+ */
    padding-top: calc(56px + env(safe-area-inset-top)) !important;
}

.App-toast-container-ios {
    /*z-index: 1299 !important;*/ /* React MUI menu component has z-index 1300 */
    /*-webkit-transform: none;*/ /* React MUI menu component has no translateZ(...px) */
    /* Status bar height on iOS 10 */
    margin-top: 76px !important;
    /* Status bar height on iOS 11.0 */
    margin-top: calc(56px + constant(safe-area-inset-top)) !important;
    /* Status bar height on iOS 11+ */
    margin-top: calc(56px + env(safe-area-inset-top)) !important;
}

@media (min-width: 0px) and (orientation: landscape) {
    .App-drawer-header-ios, .App-dialog-header-ios {
        /* Status bar height on iOS 10 */
        min-height: 68px !important;
        /* Status bar height on iOS 11.0 */
        min-height: calc(48px + constant(safe-area-inset-top)) !important;
        /* Status bar height on iOS 11+ */
        min-height: calc(48px + env(safe-area-inset-top)) !important;
    }

    .App-toast-container-ios {
        /* Status bar height on iOS 10 */
        padding-top: 68px !important;
        /* Status bar height on iOS 11.0 */
        padding-top: calc(48px + constant(safe-area-inset-top)) !important;
        /* Status bar height on iOS 11+ */
        padding-top: calc(48px + env(safe-area-inset-top)) !important;
    }
}

@media (min-width: 600px) {
    .App-drawer-header-ios, .App-dialog-header-ios {
        /* Status bar height on iOS 10 */
        min-height: 84px !important;
        /* Status bar height on iOS 11.0 */
        min-height: calc(64px + constant(safe-area-inset-top)) !important;
        /* Status bar height on iOS 11+ */
        min-height: calc(64px + env(safe-area-inset-top)) !important;
    }

    .App-toast-container-ios {
        /* Status bar height on iOS 10 */
        padding-top: 84px !important;
        /* Status bar height on iOS 11.0 */
        padding-top: calc(64px + constant(safe-area-inset-top)) !important;
        /* Status bar height on iOS 11+ */
        padding-top: calc(64px + env(safe-area-inset-top)) !important;
    }
}

@media (max-width: 600px) {
    .hideOnMobile {
        display: none !important;
    }

    .blockOnMobile {
        display: block !important;
    }

    .relativeOnMobile {
        position: relative !important;
    }
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.animatedGradient {
    background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
    background-size: 400% 400%;
    animation: gradientAnimation 15s ease infinite;
}

@keyframes gradientAnimation {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

.pulsatingBackground {
    --background-color-from: transparent;
    --background-color-to: black;
    --color-from: black;
    --color-to: white;
    animation: fadeInOutAnimation 2s infinite;
}

@keyframes fadeInOutAnimation {
    0% {
        background-color: var(--background-color-from);
        color: var(--color-from);
    }
    50% {
        background-color: var(--background-color-to);
        color: var(--color-to);
    }
    100% {
        background-color: var(--background-color-from);
        color: var(--color-from);
    }
}
